import React from "react";
import "./style.scss";
const TermsConditionsScreen = () => {
  return (
    <div>
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style dangerouslySetInnerHTML={{__html: "\n<!--\n* {font-family:\"Calibri\",sans-serif;}\nul li a {font-weight: 700;text-decoration: none; color: #000;} \n /* Style Definitions */\n p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText\n\t{margin:0in;\n\tfont-size:10.0pt;\n\tfont-family:\"Calibri\",sans-serif;\n\tcolor:black;\n\tborder:none;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:8.0pt;\n\tmargin-left:.5in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",sans-serif;\n\tcolor:black;\n\tborder:none;}\np.Body, li.Body, div.Body\n\t{mso-style-name:Body;\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:8.0pt;\n\tmargin-left:0in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Calibri\",sans-serif;\n\tcolor:black;\n\tborder:none;}\nspan.Hyperlink0\n\t{mso-style-name:\"Hyperlink\\.0\";\n\tfont-family:\"Calibri\",sans-serif;\n\tcolor:#0563C1;\n\ttext-decoration:underline;}\n.MsoChpDefault\n\t{font-size:10.0pt;\n\tborder:none;}\n\n /* List Definitions */\na   {text-decoration: none;font-size: 16px;}\n-->\n" }} />
  <div className="WordSection1">
    <p className="Body" align="center" style={{textAlign: 'center'}}><b><span style={{fontSize: '16.0pt', lineHeight: '107%'}}>HZM Terms &amp; Conditions (</span></b><span dir="RTL" /><b><span lang="AR-SA" dir="RTL" style={{fontSize: '16.0pt', lineHeight: '107%', fontFamily: '"Arial Unicode MS",sans-serif'}}><span dir="RTL" />“</span></b><b><span style={{fontSize: '16.0pt', lineHeight: '107%'}}>T&amp;C”)</span></b></p>
    <p className="Body">&nbsp;</p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>Effective
        Date of T&amp;C: </b><b><span style={{fontSize: '10.0pt', lineHeight: '107%', background: '#fff'}}>07 Dec 2021</span></b></p>
    <p className="Body">&nbsp;</p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b><span lang="PT">[LAST UPDATED</span>– 07/Dec</b><b><span lang="PT">]</span></b></p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="Body" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '12.0pt', lineHeight: '107%'}}>TABLE OF CONTENTS</span></u></b></p>
    <p className="Body" align="center" style={{textAlign: 'center'}}><b><u><span style={{textDecoration: 'none'}}>&nbsp;</span></u></b></p>
    <ul>
      <li><a href="#accept"> ACCEPTANCE OF T&amp;C, SUPPLEMENTAL TERMS &amp; CONDITIONS, AND AMENDEMNTS</a></li>
      <li><a href="#description"> DESCRIPTION OF HZM SERVICES, DOWNLOAD OF HZM SERVICES, OUR PROPERTY, LICENSE, AND USAGE</a></li>
      <li><a href="#creation"> CREATION OF ACCOUNT, DIGITAL ITEMS, PRIZES, DELETING OF ACCOUNT, ACCURACY OF INFORMATION</a></li>
      <li><a href="#conduct"> YOUR CONDUCT</a></li>
      <li><a href="#contri"> YOUR CONTRIBUTIONS &amp; SUBMISSIONS</a></li>
      <li><a href="#avia"> AVAILABILITY OF HZM SERVICES, ACCOUNT, CONTRIBUTIONS &amp; SUBMISSIONS</a></li>
      <li><a href="#limit"> LIMITATIONS OF WARRANTY, LIABILITY, INDEMNIFICATIONS</a></li>
      <li><a href="#monitor"> MONITORING</a></li>
      <li><a href="#third-party"> THIRD-PARTY WEBSITES AND THIRD-PARTY CONTENT, ADVERTISERS, INTELLECTUAL PROPERTY NOTIFICATION</a></li>
      <li><a href="#cali"> USA, CALIFORNIA RESIDENTS</a></li>
      <li><a href="#notices"> NOTICES</a></li>
      <li><a href="#provision"> GENERAL PROVISIONS, MISCELLANEOUS</a></li>
    </ul>
    <p className="Body"><b>&nbsp;</b></p>
    <p className="Body"><b>&nbsp;</b></p>
    <p className="Body"><b>&nbsp;</b></p>
    <p id="accept" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>ACCEPTANCE OF T&amp;C, SUPPLEMENTAL
            TERMS &amp; CONDITIONS, AND AMENDEMNTS<br />
            <br />
          </span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>1)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>HZM (“we,” “us” or “our”), its owners and employees,&nbsp;
      (collectively, “<b>HZM</b>”) kindly requests that you carefully read these
      T&amp;C and the PRIVACY POLICY (“<b>PP</b>”) prior to the usage of any product,
      game, mobile application, content, websites that are produced by HZM, and any
      service that belongs to and/or is provided by HZM (“<b>HZM SERVICES</b>”).</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>2)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>These T&amp;C constitute a legally binding agreement made between you,
      and HZM concerning your access to and use of HZM SERVICES. The PP shall form an
      integral part of the T&amp;C and shall be understood to be a part of the
      T&amp;C. You will be required to accept and agree to these T&amp;C and PP prior
      to your usage of HZM SERVICES; otherwise, if you object and reject these
      T&amp;C YOU ARE EXPRESSLY PROHIBITED FROM USING HZM SERVICES AND YOU MUST
      DISCONTINUE USE IMMEDIATELY.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>3)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Supplemental terms and conditions or documents that may be posted on HZM
      SERVICES from time to time are hereby expressly incorporated into these T&amp;C
      and shall form an integral part thereof. We reserve the right, in our sole
      discretion, to make changes or modifications to these T&amp;C.&nbsp; We have the
      right to decide the method of notification regarding any change(s) of these
      T&amp;C and you will be informed regarding the change(s) thereof. Such method
      shall be but not limited to: (1) providing notices within HZM SERVICES or (2)
      notifying you via your email account that is saved in our records. The
      change(s) shall take effect thirty (30) days after the notification and shall
      be published on the T&amp;C section of HZM SERVICES as evidenced by the “<b>LAST
        UPDATED”</b> date of the T&amp;C.&nbsp; You shall be entitled to reject the
      change(s) prior to the thirty (30) day period and shall be entitled to withdraw
      from the T&amp;C and discontinue your use of HZM SERVICES. However, if you do
      not object to the change(s), remain silent and continue your use of HZM
      SERVICES after the thirty (30) day period and after the “<b>LAST UPDATED”</b>
      date of such revised T&amp;C, it will be deemed that you have read, agreed to,
      and accepted the changes thereof.</p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="description" className="Body" align="center" style={{marginLeft: '.5in', textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>HZM SERVICES, DOWNLOAD OF HZM
            SERVICES, OUR PROPERTY, AND LICENSE</span></u></b> </p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>4)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Brief Description of HZM SERVICES: HZM Services shall involve a virtual
      carrom game with its own unique rules which can be found on HZM SERVICES
      sections concerning instructions and how to play.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>5)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>When you use access and download HZM SERVICES obtained from either the
      Apple Store or Google Play (each an “<b>App Distributor</b>”), the following
      shall apply: (1) the license granted to you for HZM SERVICES is limited to a
      non-transferable license to use HZM SERVICES on a device that utilizes the
      Apple iOS or Android operating systems, as applicable, and in accordance with
      the usage rules set forth in the applicable App Distributor’s terms of service;
      (2) we are responsible for providing any maintenance and support services with
      respect to HZM SERVICES as specified in these T&amp;C or as otherwise required
      under applicable law, and you acknowledge that each App Distributor has no
      obligation whatsoever to furnish any maintenance and support services with
      respect to HZM SERVICES; (3) in the event of any failure of HZM SERVICES to
      conform to any applicable warranty, you may notify the applicable App
      Distributor, and the App Distributor, in accordance with its terms and
      policies, may refund the purchase price, if any, paid for HZM SERVICES, and to
      the maximum extent permitted by applicable law, the App Distributor will have
      no other warranty obligation whatsoever with respect to HZM SERVICES;&nbsp; (4) you
      must comply with applicable third-party terms of agreement when using the
      mobile application, e.g., if you have a VoIP application, then you must not be
      in violation of their wireless data service agreement when using HZM SERVICES;
      and (6) you acknowledge and agree that the App Distributors are third-party beneficiaries
      of HZM SERVICES, and that each App Distributor will have the right (and will be
      deemed to have accepted the right) to enforce the T&amp;C of HZM SERVICES
      contained herein against you as a third-party beneficiary thereof. </p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>6)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Unless otherwise indicated, HZM SERVICES is our property and all, mobile
      application, source code, databases, functionality, software, website designs,
      audio, video, text, photographs, and graphics on HZM SERVICES and the
      trademarks, service marks, and logos contained therein are defined and are part
      of HZM SERVICES and are owned or controlled by us or licensed to us, and are
      protected by copyright and trademark laws and various other intellectual
      property rights and unfair competition laws of the United States, foreign
      jurisdictions, and international conventions.&nbsp; HZM SERVICES is provided “AS IS”
      for your information and personal use only.&nbsp; Except as expressly provided in
      these T&amp;C, no part of HZM SERVICES may be (1) copied, reproduced,
      aggregated, republished, uploaded, posted, publicly displayed, encoded,
      translated, transmitted, distributed, sold, licensed, or otherwise exploited
      for any commercial purpose whatsoever, without our express prior written
      permission (2) decompiled, reverse engineered, disassembled, attempted to derive
      the source code of, or decrHZMted; (3) make any modifications, adaptations,
      improvements, enhancements, translations, or derivative work of HZM SERVICES;
      (4) violate any applicable laws, rules, or regulations in connection with your
      access or use of HZM SERVICES; (4) remove, alter, or obscure any proprietary
      notice (including any notice of copyright or trademark) posted by us or the
      licensors of HZM SERVICES; (5) use HZM SERVICES for any revenue generating
      endeavor, commercial enterprise, or other purpose for which it is not designed
      or intended; (6) make HZM SERVICES available over a network or other
      environment permitting access or use by multiple devices or users at the same
      time; (7) use HZM SERVICES for creating a product, service, or software that is,
      directly or indirectly, competitive with or in any way a substitute for HZM
      SERVICES; (8) use HZM SERVICES to send automated queries to any website or to
      send any unsolicited commercial e-mail; or (9) use any proprietary information
      or any of our interfaces or our other intellectual property in the design,
      development, manufacture, licensing, or distribution of any applications,
      accessories, or devices for use with HZM SERVICES.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Provided
      that you are eligible to use HZM SERVICES, you are granted a revocable,
      non-exclusive, non-transferable, limited license to, use access, download,
      install and HZM SERVICES for the purposes it was created for and as per these
      T&amp;C, and on wireless electronic devices owned or controlled by you and to
      download or print a copy of any portion of the HZM SERVICES to which you have
      properly gained access solely for your personal, entertainment, and
      non-commercial use. We reserve all rights not expressly granted to you
      regarding HZM SERVICES.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Any
      violation of the foregoing, or any violation of these T&amp;C, may result in,
      among other things, the termination or suspension of your usage of HZM SERVICES
      or parts thereof.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>7)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>HZM SERVICES is not intended for distribution to or use by any person or
      entity in any jurisdiction or country where such distribution or use would be
      contrary to law or regulation or which would subject us to any registration
      requirement within such jurisdiction or country. Accordingly, those persons who
      choose to access HZM SERVICES from other locations do so on their own
      initiative and are solely responsible for compliance with local laws, if and to
      the extent local laws are applicable</p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="creation" className="Body" align="center" style={{marginLeft: '.5in', textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>CREATION OF ACCOUNT, DIGITAL ITEMS,
            PRIZES, DELETING OF ACCOUNT, ACCURACY OF INFORMATION</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>8)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>You will be required to register and create an account with HZM
      SERVICES, which may involve information that is personal (“<b>Account</b>”). We
      may request the accuracy of any of the information provided. You agree to keep
      your Account’s password confidential and you agree that you will be responsible
      for all use of your Account. We reserve the right to remove, reclaim, or change
      a username you select if we determine, in our sole discretion, that such
      username is inappropriate, obscene, or otherwise objectionable.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>In
      all cases, you shall be liable for your Account and it will be understood that
      your Account is controlled, operated, and conducted by you. As a result, you
      hereby agree to be held liable and responsible for all operations and actions
      emanating from your Account regardless of whether such operations and actions
      have been explicitly approved by you and the foregoing extends to any issues,&nbsp;
      such as, but not limited to, losses incurred by HZM SERVICES which arise as a
      result of the conduct emanating from your Account.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Moreover,
      you shall only be allowed to create and use one (1) Account for your use and
      such Account shall only be used in relation with HZM SERVICES.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>9)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Nowhere in these T&amp;C, or anywhere on HZM SERVICES, shall it be
      interpreted or implied that you are the owner of the Account that you create
      with HZM SERVICES or that such Account is your property, regardless of the
      terms used to refer to the Account, such as but not limited to <i>your</i> or <i>yours</i>.
      The Account is for the sole purpose of participating in HZM SERVICES only. HZM
      is the sole owner of your Account and you are only granted a license to use
      such Account.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>10)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      SERVICES is intended for <b>users</b> (“users” or “user” shall mean any, user,
      or player, or participant, in HZM SERVICES”) who are natural persons, and are
      at least 16 years of age. Users who are considered minors in their respective
      jurisdiction must have the permission of, and be directly supervised by, their
      parent or guardian to use HZM SERVICES. If you are a minor, you must have your
      parent or read, agree, and accept these T&amp;C, on your behalf and be fully
      responsible for the creation of your Account prior to your using of HZM
      SERVICES. Furthermore, if you are below 16 years of age, you shall not use HZM
      SERVICES or sign up for the creation of an Account or provide any tHZMe of
      information to HZM SERVICES.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>11)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span><i>Options
        of creating an account</i>:</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>You
      must create an account yourself by using either of the following methods:</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>a)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>registering manually through HZM SERVICES by entering your email address
      and entering the required information. Such required information shall include
      your mobile number which HZM SERVICES will use to send you a mobile one-time
      password confirmation which will include a verification code,</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>b)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Linking an&nbsp; online account that you have with third-party service
      providers , such as Facebook or Gmail, (“<b>Third-Party Account</b>”) by
      providing your Third-Party Account login information to HZM SERVICES to create
      your Account; this account creation method will allow us to access your
      Third-Party Account, as is permitted under the applicable terms and conditions
      that govern your use of each Third-Party Account. You represent and warrant
      that you are entitled to disclose your Third-Party Account information to us
      and/or grant us access to your Third-Party Account login information, without
      breach by you of any of the terms and conditions that govern your use of the
      applicable Third-Party Account, and without obligating us to pay any fees or
      making us subject to any usage limitations imposed by the third-party service
      provider of the Third-Party Account. Please note that if a Third-Party Account
      or associated service becomes unavailable or our access to such Third-Party
      Account is terminated by the third-party service provider, then your Account
      may be suspended or access to your Account may become unavailable or limited;
      in such case you will be required to contact HZM for assistance<span style={{background: '#fff'}}>. You will have the ability to disable the connection
        between your Account on HZM SERVICES and your Third-Party Accounts at any time
        by contacting us or through your account settings<a href="#_ftn1" name="_ftnref1" title><sup><sup><span style={{fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[1]</span></sup></sup></a>.</span>
      PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
      ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR
      AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>12)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>Deleting
      your Account shall be performed at any time upon your request. The deletion of
      your Account shall be processed in accordance with the PP.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>HZM
      is not required to provide any type of compensation or any type of benefits if
      your Account is deleted either by your own volition or by HZM, unless as
      applicable by law.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      it appears that your Account has been inactive for <span style={{background: '#fff'}}>5 consecutive Gregorian months, we shall deactivate your Account<a href="#_ftn2" name="_ftnref2" title><sup><sup><span style={{fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[2]</span></sup></sup></a>.</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><span style={{background: '#fff'}}>&nbsp;</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>13)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>The
      T&amp;C shall remain in full force and effect as long as you are participating
      in HZM SERVICES. The T&amp;C can be terminated upon your request and subsequent
      deletion of your Account or as per the instances and conditions stated in this
      T&amp;C.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>14)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      offers you digital items for use within HZM Services as part of your
      participation in HZM SERVICES (“<b>Digital Items</b>”). Expressions, in these
      T&amp;C or HZM SERVICES, such as but not limited to, <i>obtaining</i> or <i>earning</i>-
      regardless of the terms used to describe them - are only utilized for
      explanatory purposes and shall not imply that you have a proprietary right to
      the Digital Items. HZM is the sole owner of the Digital Items and you are only
      granted a license to use such Digital Items which shall only be used in
      connection with HZM SERVICES. Digital Items shall not imply that you have any
      real balance or real-world currency and is not a reflection of any monetary
      value in the real world; the ultimate purpose of such Digital Items is for use
      within the confines of HZM SERVICES and the intention for which it was made.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>As
      part of the functionality of and your participation in HZM SERVICES, it may be
      possible to send or receive Digital Items to and from other users of HZM
      SERVICES for use within HZM SERVICES and for the purposes intended of such
      Digital Items and as per the restrictions and limitations stipulated in HZM
      SERVICES regarding the method and usage thereof.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>15)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>As
      part of HZM SERVICES, we may offer <i>monetary prizes</i> which involves your
      direct participation in certain functions offered within HZM SERVICES (“<b>Prize
        Offers</b>”). You are not obligated to accept and participate in the Prize
      Offers and we are not obligated to provide you with the opportunity to
      participate in the Prize Offers unless you fulfill certain<i> terms and
        conditions </i>unique to the Prize Offers (“<b>Prize T&amp;C</b>”); therefore,
      if we choose to offer you the Prize Offers and if you subsequently choose to
      accept and participate directly in the Prize Offers, you will be bound by the
      Prize T&amp;C and its limitations if any. You will be required to read the
      Prize T&amp;C and agree to be bound by it before your participation.
      Ultimately, you will only be able to participate in the Prize Offers if you
      agree and accept to be bound by the Prize T&amp;C in its entirety; otherwise,
      if you do not accept and agree to be bound by the Prize T&amp;C you will not be
      able to participate in the Prize Offers. Any participation in the Prize Offers
      will indicate that you have read, agreed, and accepted the Prize T&amp;C.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Prize
      Offers may be subject to steps and procedures, all of which are contained in
      the Prize T&amp;C, and if you do not fulfill such steps and procedures, or
      appear to be ineligible or unable to fulfill such steps and procedures, you may
      not be able to receive the Prize Offers irrespective of whether you have
      accepted and participated in the Prize Offers.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Prize
      Offers are not subject to any exchange or transfers for other items or things
      that have any type of value or non-value whether within HZM SERVICES or
      otherwise, and such Prize Offers are to be utilized by you only and for the
      purpose it was intended for.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>In
      all cases, once you participate in the Prizes Offers, these T&amp;C shall apply
      in conjunction with the Prize T&amp;C.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>16)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>By
      using HZM SERVICES, you represent and warrant that: (1) all registration
      information you submit will be true, accurate, current, and complete;<b> </b>(2)
      you will maintain the accuracy of such information and promptly update such
      registration information as necessary;<b> </b>(3) you have the legal capacity
      and you agree to comply with these T&amp;C; (4) you are not under the age of 13
      (or 16 in EEA); (5) you are not a minor in the jurisdiction in which you
      reside, or if a minor, you have received parental or legal guardian permission
      to use HZM SERVICES and that parents or legal guardians thereof have read,
      understood and agreed to comply with these T&amp;C; (6) you will not access HZM
      SERVICES through automated or non-human means, whether through a bot, script or
      otherwise; (7) you will not use HZM SERVICES for any illegal or unauthorized
      purpose;&nbsp; and (8) your use of HZM SERVICES will not violate any applicable laws
      or regulation.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      you provide any information that is untrue, inaccurate, not current, or
      incomplete, we have the right to suspend or terminate your Account and refuse
      any and all current or future use of HZM SERVICES (or any portion thereof).</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="conduct" className="Body" align="center" style={{textAlign: 'center', textIndent: '.5in'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>YOUR CONDUCT</span></u></b></p>
    <p className="MsoListParagraph" style={{textIndent: '-.25in'}}>17)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;
      </span>You may not access or use HZM SERVICES for any purpose other than that
      for which we make HZM SERVICES available. HZM SERVICES may not be used in
      connection with any commercial endeavors except those that are specifically
      endorsed or approved by us:</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph">As a user of HZM SERVICES, you agree not to:</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>a)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Systematically retrieve data or other content from HZM SERVICES to
      create or compile, directly or indirectly, a collection, compilation, database,
      or directory without written permission from us.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>b)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Make any unauthorized use of HZM SERVICES, including collecting
      usernames and/or email addresses of users by electronic or other means for the
      purpose of sending unsolicited email, or creating user accounts by automated
      means or under false pretenses.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>c)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Engage a third party to participate or perform any type of activity on
      your behalf in exchange for a fee or renumeration so as to commercialize your
      participation HZM SERVICES.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>d)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Use HZM SERVICES to advertise or offer to sell goods and services, to
      advertise or perform any commercial solicitation, including, but not limited
      to, the solicitation of users to become subscribers of other on-line
      information services competitive with HZM SERVICES, or the solicitation of
      donations, or the solicitation to partake in charities notwithstanding the type
      of donation, solicitation, to whom, and to which organization or body.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>e)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Circumvent, disable, or otherwise interfere with security-related
      features of HZM SERVICES, including features that prevent or restrict the use
      or copying of any element of HZM SERVICE or enforce limitations on the use of
      HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>f)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Engage in unauthorized framing of or linking to HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>g)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Trick, defraud, or mislead us and other users of HZM SERVICES, or any
      attempt to learn sensitive account information such as users’ passwords.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>h)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Make improper use of HZM SERVICES, or support services or submit false
      reports of abuse or misconduct or use any ways of cheating that may alter the
      result of the participation of HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>i)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Interfere with, disrupt, or create an undue burden on HZM SERVICES or
      the networks or services connected to HZM SERVICES, or use any means that
      hinders or disables any part of HZM SERVICES including advertisements that are
      within HZM SERVICES.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>j)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Attempt to impersonate another user of HZM SERVICES or any person or use
      the username of another user of HZM SERVICES or create an Account representing
      another person or on behalf of another person other than yourself or create an
      Account by impersonating another person or using a fake identity or information
      to create an Account.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>k)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Sell or otherwise transfer your Account in any tHZMe of shape or form
      without the explicit and express prior written approval of HZM; in case such
      written approval is granted, you shall not use or partake in the Account
      without the explicit and express prior written approval of HZM.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>l)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Transfer the Digital Items in the physical world for any reasons, or to
      another digital medium unrelated to HZM SERVICES. You are required to use
      Digital Items for HZM SERVICES only and for the purposes that HZM created the
      Digital Items for using the methods that HZM SERVICES makes available only. Any
      type of exchange of Digital Items shall be prohibited unless as approved in
      writing and following the explicit methods of exchange dictated by HZM
      SERVICES. Furthermore, any means to generate Digital Items which are not
      performed in the way that HZM SERVICES makes it available is strictly
      prohibited.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>m)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;
      </span>Use any information obtained from HZM SERVICES in order to harass,
      abuse, or harm another person, any conduct restricts or inhibits any other user
      from using or enjoying HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>n)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Attempt to bypass any measures of HZM SERVICES designed to prevent or
      restrict access to HZM SERVICES, or any portion of HZM SERVICES or hide your IP
      address by any means.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>o)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Harass, annoy, intimidate, or threaten any of our employees or agents
      engaged in providing any portion of HZM SERVICES to you.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>p)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Delete the copyright or other proprietary rights notice from HZM
      SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>q)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Copy or adapt HZM SERVICES’ software, including but not limited to
      Flash, PHP, HTML, JavaScript, or other code.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>r)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan
      horses, or other material, including excessive use of capital letters and
      spamming (continuous posting of repetitive text), that interferes with any party’s/users
      uninterrupted use and enjoyment of HZM SERVICES, or modifies, impairs,
      disrupts, alters, or interferes with the use, features, functions, operation,
      or maintenance of HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>s)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Upload or transmit (or attempt to upload or to transmit) any material
      that acts as a passive or active information collection or transmission
      mechanism, including without limitation, clear graphics interchange formats
      (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes
      referred to as “spyware” or “passive collection mechanisms” or “pcms” or any
      type of technology that is used for such purposes.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>t)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Engage in any automated use of the system, such as using scripts to send
      comments or messages, or using any data and information mining tools, robots,
      or similar or uniquely different data gathering and extraction tools.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>u)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Except as may be the result of standard search engine or Internet
      browser usage, use, launch, develop, or distribute any automated system,
      including without limitation, any spider, robot, cheat utility, scraper, or
      offline reader that accesses HZM SERVICES, or using or launching any
      unauthorized script or other software.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in', lineHeight: '115%'}}>v)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Disparage,
      tarnish, or otherwise harm, in our opinion, us and/or HZM SERVICES.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>w)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Use HZM SERVICES in a manner inconsistent with any applicable laws or
      regulations.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>x)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>You shall not perform or cause to happen any unlawful activity, post or
      transmit through any material which violates or infringes in any way upon the
      rights of others, which is unlawful, threatening, abusive, defamatory, invasive
      of privacy or publicity rights, vulgar, obscene, profane or otherwise
      objectionable, which encourages conduct that would constitute a criminal
      offense, give rise to civil liability or otherwise violate any law.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>y)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Post or otherwise make available any material protected by copyright,
      trademark or other proprietary right without the express permission of the
      owner of the copyright, trademark or other proprietary right and the burden of
      determining that any material is not protected by copyright rests with you and
      you shall be solely liable for any damage resulting from any infringement of
      copyrights, proprietary rights, or any other harm resulting from such a
      submission.</p>
    <p className="Body" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>Any
      use of HZM SERVICES in violation of these T&amp;C and the foregoing will
      violate these T&amp;C and may result in, amongst other things, termination or
      suspension of your rights to use HZM SERVICES or parts thereof.</p>
    <p className="Body" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="contri" className="Body" align="center" style={{marginLeft: '.25in', textAlign: 'center', textIndent: '.5in'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>YOUR
            CONTRIBUTIONS &amp; SUBMISSIONS</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>18)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      may invite you, and make available a technical environment, either within HZM
      SERVICE or elsewhere, to chat, contribute to, review, or participate in blogs,
      message boards, online forums, and other mediums whether through HZM SERVICES
      or another medium, and may provide you with the opportunity to create, submit,
      post, display, transmit, perform, publish, distribute, or broadcast content and
      materials, including but not limited to text, writings, video, audio,
      photographs, graphics, or other material (collectively, "<b>Contributions</b>").
      <b>However, these Contributions are not in any way a solicitation or request
        for ideas by HZM to you, or a type of <i>work</i> or <i>work for hire</i> and
        shall not be considered as such and shall not be presumed to be so</b>.
      Furthermore, you do not have the right participate unless it is provided by HZM
      and HZM SERVICES. Therefore, any Contributions you transmit may be treated as
      non-confidential and non-proprietary.&nbsp; When you create or make available any
      Contributions, you thereby represent and warrant that:</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>a)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>The creation, distribution, transmission, public display, or
      performance, and the accessing, downloading, or copying of your Contributions
      do not and will not infringe the proprietary rights, including but not limited
      to the copyright, patent, trademark, trade secret, or moral rights of any third
      party.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>b)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>You are the creator and owner of or have the necessary licenses, rights,
      consents, releases, and permissions to use and to authorize us, HZM SERVICES,
      and other users of HZM SERVICES to use your Contributions in any manner
      contemplated by HZM SERVICES and these T&amp;C.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>c)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>You have the written consent, release, and/or permission of each and
      every identifiable individual person in your Contributions to use the name or
      likeness of each and every such identifiable individual person to enable
      inclusion and use of your Contributions in any manner contemplated by HZM, HZM
      SERVICES and these T&amp;C.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textIndent: '-.25in'}}>d)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your
      Contributions are not false, inaccurate, or misleading.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>e)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions are not unsolicited or unauthorized advertising,
      promotional materials, pyramid schemes, chain letters, spam, mass mailings, or
      other forms of solicitation.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>f)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions are not obscene, lewd, lascivious, filthy, violent,
      harassing, libelous, slanderous, or otherwise objectionable (as determined by
      us).<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textIndent: '-.25in'}}>g)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Your
      Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>h)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not advocate the violent overthrow of any
      government or incite, encourage, or threaten physical harm against another.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textIndent: '-.25in'}}>i)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not violate any applicable law, regulation, or rule.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textIndent: '-.25in'}}>j)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not violate the privacy or publicity rights of any
      third party.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>k)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not contain any material that solicits personal
      information from anyone or exploits people in a sexual or violent manner.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>l)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not violate any laws concerning child pornography,
      or otherwise intended to protect the health or well-being of minors.<br />
      <br />
    </p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>m)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not include any offensive comments that are
      connected to race, national origin, gender, sexual preference, or physical
      handicap.</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>n)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>Your Contributions do not otherwise violate, or link to material that
      violates, any provision of these T&amp;C, or any applicable law or regulation</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="Body" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>Any
      use of HZM SERVICES in violation of the foregoing violates these T&amp;C and
      may result in, amongst other things, termination or suspension of your rights
      to use HZM SERVICES or parts thereof. We may also choose to remove any of your
      Contributions should it go against or has potential issues with these T&amp;C
      or&nbsp; HZM SERVICES.</p>
    <p className="Body" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}><b><i>In
          any case, all Contributions shall be dealt with in line with our PP.&nbsp; You shall
          take adequate steps for storing, backing up and saving your Contributions. Your
          Contributions shall remain yours in so far as your ownership of them is
          concerned.</i></b></p>
    <p className="Body" style={{marginLeft: '.75in', textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>19)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>By
      posting your Contributions to any part of HZM SERVICES [or making Contributions
      accessible to HZM and HZM SERVICES], you automatically grant, and you represent
      and warrant that you have the right to grant, to us an unrestricted, unlimited,
      irrevocable, perpetual, non-exclusive, right, and license to, use your
      Contributions. You waive all moral rights in your Contributions, and you
      warrant that moral rights have not otherwise been asserted in your
      Contributions.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>We
      do not assert any ownership over your Contributions.&nbsp; You retain full ownership
      of all of your Contributions and any intellectual property rights, or other
      proprietary rights associated with your Contributions.&nbsp; We are not liable for
      any statements or representations in your Contributions provided by you in any
      area related to HZM and HZM SERVICES.&nbsp; You are solely responsible for your
      Contributions to HZM and HZM SERVICES and you expressly agree to exonerate us
      from any and all responsibility whether by you or by a third party and to
      refrain from any legal action against us regarding your Contributions and this
      shall include but not limited to compensation to any third party or
      compensation to you.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>We
      have the right, in our sole and absolute discretion, (1) to edit, redact, or
      otherwise change any Contributions; (2) to re-categorize any Contributions to
      place them in more appropriate locations on HZM SERVICES; and (3) to pre-screen
      or delete any Contributions at any time and for any reason, without notice, (4)
      issue you warnings We have no obligation to monitor your Contributions
      anywhere, or any Contributions that arise as a result of interactions that you
      have with other users in HZM SERVICES or elsewhere.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Your
      Contributions and HZM’s license to such Contributions shall cease once your
      Account is terminated by your own volition or terminated by the decision of
      HZM. Any third parties that retain your Contributions as a result of HZM
      sharing your Contributions during the license use shall be outside of the
      responsibility of HZM. HZM shall try as best as practically and reasonably
      possible to remove your Contributions from HZM SERVICES</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      you apply to us to remove your Contributions, which shall be performed in
      accordance with the PP, we shall take practical and reasonable steps to comply;
      although in some instances, your Contributions may remain in HZM SERVICES cloud
      storage or physical storage systems as <i>back up information</i>. Furthermore,
      your Contributions may be retained if it is required by law.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>20)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>You
      acknowledge and agree that any questions, comments, suggestions, ideas,
      feedback, reviews, or other information regarding HZM and HZM SERVICES ("<b>Submissions</b>")
      provided by you to us, whether directly, or through public spheres or mediums,
      are non-confidential.&nbsp; We shall be entitled to the unrestricted, unlimited, irrevocable,
      perpetual, non-exclusive, right, and license to the use of these Submissions.
      You hereby waive all moral rights to any such Submissions, and you hereby
      warrant that any such Submissions are original with you or that you have the
      right to submit such Submissions.&nbsp; You agree there shall be no recourse against
      us for any alleged or actual infringement or misappropriation of any
      proprietary right in your Submissions. <b>However, these Submissions are not in
        any way a solicitation or request for ideas by HZM or HZM SERVICES to you , or
        for any type of <i>work</i> or work for hire and shall not be considered as
        such and shall not be presumed to be so.</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Your
      Submissions and HZM’s license to such Submissions shall cease once your Account
      is terminated by your own volition or terminated by the decision of HZM. Any
      third parties that retain your Submissions as a result of HZM sharing your
      Submissions during the license use shall be outside of the responsibility of
      HZM. HZM shall try as best as practically and reasonably possible to remove
      your Submissions from HZM SERVICES</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      you apply to us to remove your Submissions, which shall be performed in
      accordance with the PP, we shall take practical and reasonable steps to comply;
      although in some instances, your Submissions may remain in HZM SERVICES cloud
      storage or physical storage systems as <i>back up information</i>. Furthermore,
      your Submissions may be retained if it is required by law.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b><i>In
          any case, all </i></b><i>Submissions<b> shall be dealt with in line with our
          PP.&nbsp; You shall take adequate steps for storing, backing up and saving your </b>Submissions<b>.
          Your </b>Submissions<b> shall remain yours in so far as your ownership of them
          is concerned</b></i></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>21)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>Contributions,
      and Submissions provided to HZM in a <i>public sphere or public medium or
        through third-party websites</i>, are: (1) your responsibility; (2) HZM shall
      not be responsible for how, and in what capacity and form, users of HZM
      SERVICES, or third parties that are able to access such Contributions and
      Submissions as a result of it being in a public sphere or public medium, use
      such Contributions and Submissions; (3) whether such Contributions and
      Submissions are used in accordance with applicable rights and in accordance
      with the law.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Contributions
      and Submissions may be viewable by other users within HZM SERVICES, or by third
      parties if such Contributions and Submissions were provided in a <i>public
        sphere</i> or <i>public medium</i> or <i>through third-party websites</i>.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="avia" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>AVAILABILITY OF HZM SERVICES,
            ACCOUNT, CONTRIBUTIONS &amp; SUBMISSIONS</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>22)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>We
      reserve the right to change, modify, or remove the contents or features of HZM
      SERVICES at any time or for any reason at our sole discretion.&nbsp; We also reserve
      the right to modify or discontinue all or part of HZM SERVICES.&nbsp; We will not be
      liable to you or any third party for any modification, price change,
      suspension, or discontinuance of HZM SERVICES. Moreover, we cannot guarantee
      that HZM SERVICES will be available at all times. We may experience hardware,
      software, or other problems or need to perform maintenance related to HZM
      SERVICES, resulting in interruptions, delays, or errors. We reserve the right
      to decide the method of notification regarding the foregoing and you will be
      informed accordingly. Such method shall be but not limited to: (1) providing
      notices within HZM SERVICES or (2) notifying you via your email account that is
      saved in our records. The change(s) shall take effect thirty (30) days after
      the notification, unless such changes are required to take effect immediately.
      You shall be entitled to reject the change(s) prior to the thirty (30) day
      period and shall be entitled to withdraw from these T&amp;C and terminate your
      Account and participation with HZM SERVICES. However, if you do not object to
      the change(s), remain silent and continue your use of HZM SERVICES after the
      thirty (30) day period, it will be deemed that you agreed to, and accepted the
      changes thereof. You agree that we have no liability whatsoever for any loss,
      damage, or inconvenience caused by your inability to access or use HZM SERVICES
      during any downtime or discontinuance of HZM SERVICES.&nbsp; Nothing in these
      T&amp;C will be construed to obligate us to maintain and support HZM SERVICES
      or to supply any corrections, updates, or releases in connection therewith.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>23)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>You
      may be required to download patches, software, or other necessary tools from us
      in order to use HZM SERVICES. You acknowledge that the foregoing may be
      necessary to continue using HZM SERVICES and any download thereof shall be
      subject to these T&amp;C.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>24)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      SERVICES is under no obligation to compensate you or any third party for any of
      the instances indicated herein.</p>
    <p className="MsoListParagraph"><span style={{background: '#fff'}}>&nbsp;</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>25)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      SERVICES has the authority and right to restrict,&nbsp; revise, update, suspend,
      discontinue, or otherwise modify your Account or restrict or delay access to
      the whole or a portion of HZM SERVICES, or delay or delete your Contributions
      or Submissions as per these T&amp;C and HZM SERVICES shall be absolved from any
      liability to compensate you for such instances.</p>
    <p className="MsoListParagraph"><span style={{background: '#fff'}}>&nbsp;</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><span style={{background: '#fff'}}>&nbsp;</span></p>
    <p id="limit" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>LIMITATIONS OF WARRANTY, LIABILITY,
            INDEMNIFICATIONS</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>26)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      SERVICES IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.&nbsp; YOU AGREE THAT YOUR
      USE OF HZM SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
      BY LAW, WE DISCLAIM (1) WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION TO HZM
      SERVICES AND YOUR USE THEREOF INCLUDING, WITHOUT LIMITATION, (1) THE IMPLIED
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, (2) AND
      NON-INFRINGEMENT.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>27)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF
      HZM AND HZM SERVICES’ CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THIS
      SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
      MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
      PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
      USE HZM SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
      AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
      THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM HZM
      SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
      TRANSMITTED TO OR THROUGH HZM SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY
      ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
      ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
      OTHERWISE MADE AVAILABLE VIA HZM SERVICES. </p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>28)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      DOES NOT (1) WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
      PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH HZM SERVICES,
      ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
      BANNER OR OTHER ADVERTISING, AND (2) WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
      RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
      PROVIDERS OF PRODUCTS OR SERVICES.&nbsp; AS WITH THE PURCHASE OF A PRODUCT OR
      SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
      JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>29)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>IN
      NO EVENT WILL HZM BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
      CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
      LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES AND INSTANCES ARISING
      FROM YOUR USE OF HZM SERVICES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
      CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
      OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE TERMINATION OF
      YOUR ACCOUNT.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>30)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>CERTAIN
      STATE LAWS AND JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
      THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
      SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>31)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>IF
      YOU ARE A RESIDING IN OR ARE LOCATED IN THE EEA, SOME LIMITATIONS,
      RESTRICTIONS, THE EXCLUSIONS OR LIMITATION OR RESTRICTIONS MAY NOT APPLY TO
      YOU. HZM SHALL TAKE RESPONSIBILITY FOR A DAMAGE OR LOSS THAT IS FORSEEABLE AND
      IS A DIRECT CONSEQUENCE DUE TO HZM AND HZM SERVICES’ BREACH OF THE T&amp;C OR
      DUE TO HZM OR HZM SERVICES’ (1) GROSS NEGLIGENCE, (2) MALICE. HOWEVER, WITH
      RESPECT TO DAMAGES OR LOSSES OR NEGLIGENT ACTS THAT ARE NOT FORSEEABLE, HZM
      SHALL NOT TAKE RESPONSIBILITY.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>32)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>You
      agree to defend, indemnify, and hold us harmless, from and against any loss,
      damage, liability, claim, or demand, including attorneys’ fees and expenses,
      made by any third party due to or arising out of: (1) your Contributions or
      Submissions (2) use of HZM SERVICES;&nbsp; (3) breach of these T&amp;C; (4) any
      breach of your representations and warranties set forth in these T&amp;C; (5)
      your violation of the rights of a third party, including but not limited to
      intellectual property rights; or (6) any overt harmful act toward any other
      user of HZM SERVICES with whom you interact via HZM SERVICES or facilitated
      through HZM SERVICES. Notwithstanding the foregoing, we reserve the right, at
      your expense, to assume the exclusive defense and control of any matter for
      which you are required to indemnify us, and you agree to cooperate, at your
      expense, with our defense of such claims. We will use reasonable efforts to
      notify you of any such claim, action, or proceeding which is subject to this
      indemnification upon becoming aware of it.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="monitor" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>MONITORING</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>33)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>We
      reserve the right, but not the obligation, to:&nbsp;(1) monitor HZM SERVICES
      for violations of these T&amp;C; (2) take appropriate legal action against
      anyone who, in our sole discretion, violates the law or these T&amp;C,
      including but without limitation to reporting HZM SERVICES’ user(s) to law
      enforcement authorities or government authorities if HZM are lawfully obligated
      to report such matters or give user(s) identity to a third party which claim
      that Contributions violate their privacy rights or proprietary/intellectual
      property rights; (3) in our sole discretion and without limitation, refuse,
      restrict access to, limit the availability of, or disable (to the extent
      technologically feasible) any of your Contributions or Submissions or any
      portion thereof or your access to HZM SERVICES completely; (4) in&nbsp;our sole
      discretion and without limitation, notice, or liability, to remove from HZM
      SERVICES or otherwise disable all Contributions or Submissions that are
      excessive in size or are in any way burdensome to our systems; and (5) manage
      HZM SERVICES in a manner designed to protect our rights and property and to
      facilitate the proper functioning of HZM SERVICES (6) review your Contributions
      and Submissions and to remove it in our sole discretion (7) save and record
      your Submissions, and Contributions (8) Interfere, at our discretion but
      without obligation, for the purpose of the users’ peaceful enjoyment of HZM
      SERVICES, or as per your request but without an obligation to do so, in
      disputes that arise between you and other users of HZM SERVICES, whether
      occurring as a result of your Contributions or Submissions or as a result of
      direct interactions in HZM SERVICES with other users; however, you are solely
      and ultimately responsible for such Contributions, Submissions, and such
      interactions including any actions or disputes or legal issues that arise as a
      result thereof.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="third-party" className="Body" align="center" style={{marginLeft: '.5in', textAlign: 'center'}}><b><u><span lang="DE" style={{fontSize: '14.0pt', lineHeight: '107%'}}>THIRD-PARTY WEBSITES AND
            THIRD-PARTY CONTENT, ADVERTISERS, INTELLECTUAL PROPERTY NOTIFICATION</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>34)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>HZM
      SERVICES may contain (or you may be sent via HZM SERVICES) links to other
      websites ("<b>Third-Party Websites</b>") as well as articles,
      photographs, text, graphics, pictures, designs, music, sound, video,
      information, applications, software, and other content or items belonging to or
      originating from third parties ("<b>Third-Party Content</b>"). Such
      Third-Party Websites and Third-Party Content are not investigated, monitored,
      or checked for accuracy, appropriateness, or completeness by us, and we are not
      responsible for any Third-Party Websites accessed through HZM SERVICES or any
      Third-Party Content posted on, available through, or installed from HZM
      SERVICES, including the content, accuracy, offensiveness, opinions,
      reliability, privacy practices, or other policies of or contained in the
      Third-Party Websites or the Third-Party Content.&nbsp; Inclusion of, linking to, or
      permitting the use or installation of any Third-Party Websites or any
      Third-Party Content does not imply approval or endorsement thereof by us.&nbsp; If
      you decide to leave HZM SERVICES and access the Third-Party Websites or to use
      or install any Third-Party Content, you do so at your own risk, and you should
      be aware that these T&amp;C no longer govern. You should review the applicable
      terms and policies, including privacy and data gathering practices, of
      Third-Party Websites or any website to which you navigate from HZM SERVICES or
      relating to any applications you use or install from HZM SERVICES; we have no control
      over the aforementioned and no responsibility for their terms and policies and
      any of their practices; any data that they automatically collect from you or
      any information that they request your permission for, such as but not limited
      to, your information or personal information is outside of our control. Any
      purchases you make through Third-Party Websites will be through other websites
      and from other companies, and we take no responsibility whatsoever in relation
      to such purchases which are exclusively between you and the applicable third
      party.&nbsp; You agree and acknowledge that we do not endorse the products or
      services offered on Third-Party Websites or any Third-Party Content and you
      shall hold us harmless from any harm caused by your purchase of such products
      or services.&nbsp; Additionally, you shall hold us harmless from any losses
      sustained by you or harm caused to you relating to or resulting in any way from
      any Third-Party Content or any contact with Third-Party Websites.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>35)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>We
      allow advertisers to display their advertisements and other information in
      certain areas of HZM SERVICES, such as but not limited to, sidebar
      advertisements or banner advertisements.&nbsp; If you are an advertiser, you shall
      take full responsibility for any advertisements you place on HZM SERVICES and
      any services provided on HZM SERVICES or products sold through those
      advertisements.&nbsp; Further, as an advertiser, you warrant and represent that you
      possess all rights and authority to place advertisements on HZM SERVICES,
      including, but not limited to, intellectual property rights, publicity rights,
      and contractual rights. [As an advertiser, you agree that such advertisements
      are subject to our Digital Millennium Copyright Act (“<b>DMCA</b>”) Notice and
      Policy provisions as described below, and you understand and agree there will
      be no refund or other compensation for DMCA takedown-related issues.]&nbsp; We
      simply provide the space to place such advertisements, and we have no other
      relationship with advertisers.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>36)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>We
      respect the intellectual property rights of others.&nbsp; If you believe that any
      material available on or through HZM SERVICES infringes upon any copyright you
      own or control, please immediately notify us using the contact information
      provided below (a “<b>Notification</b>”).&nbsp; A copy of your Notification will be
      sent to the person who posted or stored the material addressed in the
      Notification.&nbsp; Please be advised that pursuant to you may be held liable for
      damages if you make material misrepresentations in a Notification. Thus, if you
      are not sure that material located on or linked to by HZM SERVICES infringes
      your copyright, you should consider first contacting an attorney.</p>
    <p className="MsoListParagraph">&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>All
      Notifications should meet the requirements of DMCA and include the following
      information: (1) A physical or electronic signature of a person authorized to
      act on behalf of the owner of an exclusive right that is allegedly infringed;
      (2) identification of the copyrighted work claimed to have been infringed, or,
      if multiple copyrighted works on HZM SERVICES are covered by the Notification,
      a representative list of such works on HZM SERVICES; (3) identification of the
      material that is claimed to be infringing or to be the subject of infringing
      activity and that is to be removed or access to which is to be disabled, and
      information reasonably sufficient to permit us to locate the material; (4)
      information reasonably sufficient to permit us to contact the complaining
      party, such as an address, telephone number, and, if available, an email
      address at which the complaining party may be contacted; (5) a statement that
      the complaining party has a good faith belief that use of the material in the
      manner complained of is not authorized by the copyright owner, its agent, or
      the law; and (6)&nbsp; a statement that the information in the notification is
      accurate, and under penalty of perjury, that the complaining party is
      authorized to act on behalf of the owner of an exclusive right that is
      allegedly infringed upon.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      you believe your own copyrighted material has been removed from HZM SERVICES as
      a result of a mistake or misidentification, you may submit a written counter
      notification to [us/ HZM SERVICES Designated Copyright Agent] using the contact
      information provided below (a “<b>Counter Notification</b>”). To be an
      effective Counter Notification under the DMCA, your Counter Notification must
      include substantially the following: (1) identification of the material that
      has been removed or disabled and the location at which the material appeared
      before it was removed or disabled; (2) a statement that you consent to the
      jurisdiction of the Federal District Court in which your address is located, or
      if your address is outside the United States, for any judicial district in
      which we are located; (3) a statement that you will accept service of process
      from the party that filed the Notification or the party's agent; (4) your name,
      address, and telephone number; (5) a statement under penalty of perjury that
      you have a good faith belief that the material in question was removed or
      disabled as a result of a mistake or misidentification of the material to be
      removed or disabled; and (6) your physical or electronic signature.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      you send us a valid, written Counter Notification meeting the requirements
      described above, we will restore your removed or disabled material, unless we
      first receive notice from the party filing the Notification informing us that
      such party has filed a court action to restrain you from engaging in infringing
      activity related to the material in question. Please note that if you
      materially misrepresent that the disabled or removed content was removed by
      mistake or misidentification, you may be liable for damages, including costs
      and attorney's fees. Filing a false Counter Notification constitutes perjury.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="Body" style={{marginLeft: '.5in'}}>Designated Copyright Agent: Mohamed
      Abdulhameed Almeamari</p>
    <p className="Body" style={{textIndent: '.5in'}}>Address: Business Bay 18<sup>th</sup>
      floor, Office 186, Building 3232, Road 4654</p>
    <p className="Body" style={{textIndent: '.5in'}}>Block 346, Manama – Sea front</p>
    <p className="Body" style={{textIndent: '.5in'}}>Kingdom of Bahrain</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>Email:
      <span className="Hyperlink0"><a href="mailto:mohamed@almeamarilaw.com">mohamed@almeamarilaw.com</a></span>
      / <span className="Hyperlink0"><a href="mailto:a.hameed@almeamarilaw.com">a.hameed@almeamarilaw.com</a></span>
    </p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="cali" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>USA, CALIFORNIA RESIDENTS</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>37)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>If
      you are a consumer living in California, USA, then the following shall apply to
      you:</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>If
      any complaint with us is not satisfactorily resolved, you can contact the
      Complaint Assistance Unit of the Division of Consumer Services of the
      California Department of Consumer Affairs in writing at 1625 North Market
      Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800)
      952-5210 or (916) 445-1254.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}>&nbsp;</p>
    <p id="notices" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>NOTICES</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>38)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>Accessing
      or visiting or using HZM SERVICES, sending us emails, and completing online
      forms constitute electronic communications.&nbsp; You consent to receive electronic
      communications, and you agree that all agreements, notices, disclosures, and other
      communications we provide to you electronically, via email, and on HZM
      SERVICES, satisfy any legal requirement that such communication be in writing.
      FURTHERMROE, YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES TO SIGN ANY
      TYPE OF AGREEMENT OR CONTRACT OR POLICY, ORDERS, OTHER RECORDS, ANY TYPE OF
      DOCUMENT THAT REQUIRES A SIGNATURE, AND TO ELECTRONIC DELIVERY OF THE FOREGOING
      DOCUMENTS, NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
      COMPLETED BY US OR VIA HZM SERVICES.&nbsp; You hereby waive any rights or
      requirements under any statutes, regulations, rules, ordinances, or other laws
      in any jurisdiction which require an original signature or delivery or
      retention of non-electronic records.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p id="provision" className="MsoListParagraph" align="center" style={{textAlign: 'center'}}><b><u><span style={{fontSize: '14.0pt', lineHeight: '107%'}}>GENERAL PROVISIONS, MISCELLANEOUS</span></u></b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>39)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>These
      T&amp;C shall be governed and construed in accordance with the laws of the
      Kingdom of Bahrain. Any dispute that may arise between you and us over the
      interpretation or application of these T&amp;C, shall be initially resolved
      through good faith negotiations within thirty (30) days of a notice of dispute
      issued by one the party to the other. In the event, such good faith
      negotiations fail, then dispute shall be referred to an Arbitral Tribunal in
      accordance with the rules and procedures provided for in Charter of the Commercial
      Arbitration Centre for the States of the Co-operation Council for the Arab
      States of the Gulf. The Arbitral Tribunal shall be composed of a single
      arbitrator. You have the discretion to opt out of the arbitration by providing
      us with a written notification to that effect within thirty days of raising the
      dispute in written notice by the concerned party in which case the dispute will
      transfer to the jurisdiction of the courts of the Kingdom of Bahrain.</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>40)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>These
      T&amp;C, and any policies or operating rules posted by us on HZM SERVICES and
      contained within HZM SERVICES constitute the entire agreement and understanding
      between you and us.</p>
    <p className="MsoListParagraph"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>41)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>Our
      failure to exercise or enforce any right or provision of these T&amp;C shall
      not operate as a waiver of such right or provision.&nbsp; These T&amp;C shall
      operate to the fullest extent permissible by law.</p>
    <p className="MsoListParagraph"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>42)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>We
      may assign any or all of our rights and obligations to others at any time with
      or without your approval and consent.&nbsp; You shall not give away your rights and
      obligations to others at any time without our written consent.</p>
    <p className="MsoListParagraph"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>43)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>If
      any provision or sub provision, or a sentence or phrase or several words or a
      number within a list of a provision or sub provisions in these T&amp;C is
      determined to be unlawful, void, or unenforceable, that provision or sub
      provision, or a sentence or phrase or several words of a provision or sub
      provisions shall be deemed severable from these T&amp;C and shall not affect
      the validity and enforceability of any remaining provisions or sub provision,
      or a sentence or phrase or several words of a provision or sub provisions in
      these T&amp;C.</p>
    <p className="MsoListParagraph"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>44)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>There
      is no joint venture, partnership, employment or agency relationship created
      between you and us as a result of these T&amp;C or use of HZM SERVICES.&nbsp; You
      agree that these T&amp;C will not be construed against us by virtue of having
      drafted them.</p>
    <p className="Body"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>45)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>In
      order to resolve a complaint regarding HZM or HZM SERVICES or to receive
      further information regarding use of HZM SERVICES, or for any of the instances
      contained in the T&amp;C that necessitates communication to us, please contact
      us at:</p>
    <p className="MsoListParagraph"><b>&nbsp;</b></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: '115%'}}><span style={{background: '#fff'}}>[Name]<a href="#_ftn3" name="_ftnref3" title><sup><sup><span style={{fontSize: '11.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[3]</span></sup></sup></a></span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: '115%'}}><span style={{background: '#fff'}}>[Corporate Address]</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: '115%'}}><span style={{background: '#fff'}}>[Corporate Phone Number]</span></p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: '115%'}}><span style={{background: '#fff'}}>[Corporate Fax Number]</span></p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '.5in'}}><span style={{background: '#fff'}}>[Email Address]</span></p>
    <p className="Body" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '.5in'}}>&nbsp;</p>
    <p className="MsoListParagraph" style={{textAlign: 'justify', textJustify: 'inter-ideograph', textIndent: '-.25in'}}>46)<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp; </span>Force
      Majeure: HZM shall not be responsible and liable for any of the following
      instances in so far as such instances prevents, cause problems, interruptions,
      delays, changes, to HZM SERVICES and its obligations: unforeseen events and
      events that are beyond the reasonable control of HZM including but not limited
      to strikes, blockade, war, mobilisation, embargoes, terrorism, revolutions or
      riots, natural disasters, fire, refusal of licence by the government or other
      governmental agencies or other stipulations or restrictions by the authorities,
      system failures, network infrastructure failures, failures of HZM SERVICES as a
      whole that occurred as a result of the instances mentioned and outside external
      factors.</p>
  </div>
  <div><br clear="all" />
    <hr align="left" size={1} width="33%" />
    <div id="ftn1">
      <p className="MsoFootnoteText" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><a href="#_ftnref1" name="_ftn1" title><sup><span style={{background: '#fff'}}><sup><span style={{fontSize: '10.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[1]</span></sup></span></sup></a><span style={{fontSize: '8.0pt', background: '#fff'}}> (NOTE FOR WEBTREE) -&nbsp; Must include
          disabling functionality on account to remove Facebook or Gmail sign in and op
          for other way of sign in.</span></p>
    </div>
    <div id="ftn2">
      <p className="MsoFootnoteText" style={{textAlign: 'justify', textJustify: 'inter-ideograph'}}><a href="#_ftnref2" name="_ftn2" title><sup><span style={{background: '#fff'}}><sup><span style={{fontSize: '10.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[2]</span></sup></span></sup></a><span style={{background: '#fff'}}> (</span><span style={{fontSize: '8.0pt', background: '#fff'}}>NOTE FOR WEBTREE ) -&nbsp; Include this in the APP functions, deactivate
          account and reactivate to stop all access to data collection while deactivated.</span></p>
    </div>
    <div id="ftn3">
      <p className="MsoFootnoteText"><a href="#_ftnref3" name="_ftn3" title><sup><span style={{background: '#fff'}}><sup><span style={{fontSize: '10.0pt', fontFamily: '"Calibri",sans-serif', color: 'black', border: 'none', background: '#fff'}}>[3]</span></sup></span></sup></a><span style={{background: '#fff'}}> info@hzmcoin.com</span></p>
    </div>
  </div>
</div>
  );
};

export default TermsConditionsScreen;
