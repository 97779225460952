import React from "react";
// import "./style.scss";
const HZMPoolAccountDelete = () => {
  return (
    <>
  <meta charSet="UTF-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>HZM/FB Disclaimer</title>
  <h1
    align="center"
    style={{
      fontSize: "xx-large",
      fontFamily: '"Courier New", Courier, monospace'
    }}
  >
    Disclaimer
  </h1>
  <div style={{margin:"40px"}}>
  <p style={{ fontSize: "x-large", }}>
    <b>HZM Pool</b> is a mobile game that uses social account usernames for
    saving and loading user data on server. According to Facebook policy, we
    have to provide User <b>Data Deletion Callback URL</b> or Data Deletion
    Instructions URL.
  </p>
  <p style={{ fontSize: "x-large",  }}>
    If you want to delete your game's account data, you can remove your data by
    following these steps: <br />
  </p>
  <ol type={1} style={{ fontSize: "x-large",  }}>
    <li>
      {" "}
      Open the <b>HZM Pool</b> app in your device in which you are logged in.
    </li>
    <li>
      {" "}
      Click on <b>"Settings"</b> button placed at bottom-left side in main menu.
    </li>
    <li>
      {" "}
      Click on <b>"Delete Account"</b> button visible at bottom on Account
      section.
    </li>
    <li>
      Finally, click on <b>"Yes"</b> button on data deletion confirmation popup.
    </li>
  </ol>
  </div>
</>

  );
};

export default HZMPoolAccountDelete;
