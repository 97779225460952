import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AUTH_ROUTES } from "./AuthRoutes";
import { PUBLIC_ROUTES } from "./PublicRoutes";
import { PRIVATE_ROUTES } from "./PrivateRoutes";
import DocumentTitle from "./DocumentTitle";
import RenderRoutes from "./RenderRoutes";


const DEFAULT_GUEST_ROUTE = "/";
const DEFAULT_AUTHENTICATED_ROUTE = "/";

const GuestRoutes = () => {
  return (
    <Switch>
      <Route exact path={PUBLIC_ROUTES.map((route) => route.path)}>       
          <RenderRoutes routes={PUBLIC_ROUTES} />        
      </Route>
      <Redirect from="*" to={DEFAULT_GUEST_ROUTE} />
    </Switch>
  );
};

const AuthenticatedRoutes = () => {
  const routes = PUBLIC_ROUTES.concat(PRIVATE_ROUTES);
  return (
      <Switch>
        <Route path={routes.map((route) => route.path)}>
          <RenderRoutes routes={routes} />
        </Route>
        <Redirect from="*" to={DEFAULT_AUTHENTICATED_ROUTE} />
      </Switch>
  );
};

const RootRouter = () => {
  const baseName = process.env.REACT_APP_BASE_NAME;
  const token = undefined;
  const isAuthenticated = false;
  return (
    <BrowserRouter basename={baseName}>
      <DocumentTitle isAuthenticated={isAuthenticated} />
      {token ? <AuthenticatedRoutes /> : <GuestRoutes />}
    </BrowserRouter>
  );
};

export default RootRouter;
