import React from "react";
import "./style.scss";
const PrizeTCScreen = () => {
  return (
    <div className="page_content">		 
		<h1>
			<i><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M23.6014 22.5334V7.46656C23.6014 6.10651 22.4949 5 21.1348 5H8.46656C7.10651 5 6 6.10651 6 7.46656V22.5334C6 23.8935 7.10651 25 8.46656 25H21.1349C22.4949 25 23.6014 23.8935 23.6014 22.5334ZM12.3281 6.53097H17.5131V7.36799C17.5131 7.88388 17.0934 8.30358 16.5775 8.30358H13.2637C12.7478 8.30358 12.3281 7.88388 12.3281 7.36799V6.53097ZM22.0704 22.5334C22.0704 23.0493 21.6507 23.469 21.1348 23.469H8.46656C7.95067 23.469 7.53097 23.0493 7.53097 22.5334V7.46656C7.53097 6.95067 7.95067 6.53097 8.46656 6.53097H10.7972V7.36799C10.7972 8.72804 11.9037 9.83455 13.2637 9.83455H16.5776C17.9376 9.83455 19.0441 8.72804 19.0441 7.36799V6.53097H21.1349C21.6508 6.53097 22.0705 6.95067 22.0705 7.46656V22.5334H22.0704Z" fill="white"/>
			<path d="M13.1872 11.4761H9.45836C9.0356 11.4761 8.69287 11.8188 8.69287 12.2416V15.1198C8.69287 15.5426 9.0356 15.8853 9.45836 15.8853H13.1872C13.61 15.8853 13.9527 15.5426 13.9527 15.1198V12.2416C13.9527 11.8188 13.61 11.4761 13.1872 11.4761ZM12.4217 14.3543H10.2238V13.007H12.4217V14.3543V14.3543Z" fill="white"/>
			<path d="M20.1431 12.9153H15.6402C15.2175 12.9153 14.8748 13.258 14.8748 13.6808C14.8748 14.1035 15.2175 14.4463 15.6402 14.4463H20.1431C20.5658 14.4463 20.9086 14.1035 20.9086 13.6808C20.9086 13.258 20.5658 12.9153 20.1431 12.9153Z" fill="white"/>
			<path d="M13.1872 17.2327H9.45836C9.0356 17.2327 8.69287 17.5754 8.69287 17.9982V20.8765C8.69287 21.2992 9.0356 21.6419 9.45836 21.6419H13.1872C13.61 21.6419 13.9527 21.2992 13.9527 20.8765V17.9982C13.9527 17.5754 13.61 17.2327 13.1872 17.2327ZM12.4217 20.111H10.2238V18.7636H12.4217V20.111V20.111Z" fill="white"/>
			<path d="M20.1431 18.6719H15.6402C15.2175 18.6719 14.8748 19.0146 14.8748 19.4374C14.8748 19.8601 15.2175 20.2028 15.6402 20.2028H20.1431C20.5658 20.2028 20.9086 19.8601 20.9086 19.4374C20.9086 19.0146 20.5658 18.6719 20.1431 18.6719Z" fill="white"/></svg></i> 
			Privay Policy
		</h1>
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>		
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>		
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>		
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
		<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
	</div>
  );
};

export default PrizeTCScreen;
