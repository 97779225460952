import RootRouter from "./Routes/RootRouter";
import './App.css';

function App() {
  return (
    <RootRouter />
  );
}

export default App;
