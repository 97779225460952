import {
  PrizeTCScreen, PrivacyPolicyScreen, TermsConditionsScreen, HZMPoolAccountDelete
} from "../Views/Public";
import { ROUTE_CONSTANTS } from '../Shared/Routes';
export const PUBLIC_ROUTES = [
  {
    path: ROUTE_CONSTANTS.PRIVACY_POLICY,
    component: PrivacyPolicyScreen,
    title: "Privacy Policy",
  },
  {
    path: ROUTE_CONSTANTS.PRIZE_TC,
    component: PrizeTCScreen,
    title:'Prize Terms & Conditions',
  },{
    path: ROUTE_CONSTANTS.TERMS_CONDITIONS,
    component: TermsConditionsScreen,
    title:'Terms & Conditions',
  },{
    path: ROUTE_CONSTANTS.HZM_POOL_ACCOUNT_DELETE,
    component: HZMPoolAccountDelete,
    title:'HZM/FB Disclaimer',
  },];
